<template>
  <div id="Sharing" class="coachAction">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit1">
          {{ $t('Sharing.title1') }}
        </p>
        <p class="bg-tit2">
          {{ $t('Sharing.title2') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <div class="top-right-con"></div>
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="center-background">
      <div class="center-container" style="max-width: 1200px; margin: 0 auto">
        <div class="title-text">
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-left-or.svg" alt="" />
          </span>
          <span class="text-blue">
            {{ $t('Sharing.title3') }}
          </span>
          <span>
            <img class="connet" src="../assets/img/2-Icons/icons_connect-right-or.svg" alt="" />
          </span>
        </div>

        <!-- english language section -->
        <div class="content-flex" v-if="$i18n.locale == 'en'">
          <div class="flex-item" v-for="(item, index) in shargingList" :key="index">
            <div class="flex-bg" :style="item.banner"><!--<div class="soon">{{$t(`Sharing.${item.title}`)}} </div> --></div>
            <div class="flex-info">
              <div class="flex-tit1" v-if="item.tit1" v-html="$t(`${item.tit1}`)"></div>
              <div class="speaker_name">
                {{ item.speakerName }}
                <p v-if="item.speakerName2">{{ item.speakerName2 }}</p>
              </div>
              <div class="flex-tit2" v-if="item.tit2" v-html="$t(`${item.tit2}`)"></div>
              <div class="flex-tit2" v-if="item.tit4">Date : {{ $t(`${item.tit4}`) }}</div>
              <div class="flex-tit2" v-if="item.tit6">Time : {{ $t(`${item.tit6}`) }}</div>
              <div class="flex-tit2" v-if="item.partner" @click="showpartner(index)">
                {{ $t(`Partner`) }}: <span style="text-decoration: underline; cursor: pointer">{{ item.partner }} </span>
              </div>
              <div class="flex-tit3" v-if="item.tit3" v-html="$t(`${item.tit3}`)"></div>
              <div class="flex-btn1" v-if="item.btn1">{{ item.btn1 }}</div>

              <div class="flex-btn2" v-if="item.btn2" @click="showtit5(index)">{{ item.btn2 }}</div>

              <div class="flex-btn2 btn-replay-1" v-if="index == 1 && coachId" @click="handleClick('https://youtu.be/sI5hs5YQxQs', item.id, item.tit1, item.tit4, item.tit6, (click = item.id))">
                Replay Online Sharing
              </div>
              <div class="flex-btn2 btn-download-1" v-if="index == 1" onclick="location.href='https://drive.google.com/file/d/10IFVbU8Q4AAJs6NinENoW_qP7UlFBTq5/view?usp=sharing';">
                Download Seminar Handouts
              </div>
              <div class="flex-btn2 btn-replay-2" ref="button_one" v-if="index == 3 && coachId" @click="handleClick('https://youtu.be/06Ck7mE7acI', item.id, item.tit1, item.tit4, item.tit6)">
                Replay Online Sharing
              </div>
              <div class="flex-btn2 btn-download-2" v-if="index == 3" onclick="location.href='https://drive.google.com/file/d/17ZcQD67otGpa-OBqQb4HHKSc1k3vfxZ5/view?usp=sharing';">
                Download Seminar Handouts
              </div>
              <div class="flex-btn2 btn-replay-3" v-if="index === 4 && coachId" @click="handleClick('https://youtu.be/S-vHxUwc0c', item.id, item.tit1, item.tit4, item.tit6)">
                Replay Online Sharing
              </div>
              <div class="flex-btn2 btn-download-3" v-if="index === 4" onclick="location.href='https://drive.google.com/file/d/1j_lpIk0OzSGfPphfhjKOc5hHCenXet9b/view?usp=sharing'">
                Download Seminar Handouts
              </div>
              <div class="flex-btn2 btn-download-4" v-if="index === 4" onclick="location.href='https://drive.google.com/file/d/1t8e1eVTzlW602Cx-VBrpCr7qB9Z9NNUD/view?usp=sharing'">
                Download Seminar Handouts
              </div>
            </div>
          </div>
        </div>

        <!-- chinese language section -->
        <div class="content-flex" v-if="$i18n.locale == 'cn'">
          <div class="flex-item" v-for="(item, index) in shargingListChinese" :key="index">
            <div class="flex-bg" :style="item.banner"><!--<div class="soon">{{$t(`Sharing.${item.title}`)}} </div> --></div>
            <div class="flex-info">
              <div class="flex-tit1" v-if="item.tit1" v-html="$t(`${item.tit1}`)"></div>
              <div class="speaker_name">
                {{ item.speakerName }}
              </div>
              <div class="flex-tit2" v-if="item.tit2" v-html="$t(`${item.tit2}`)"></div>
              <div class="flex-tit2" v-if="item.tit4">日期 : {{ $t(`${item.tit4}`) }}</div>
              <div class="flex-tit2" v-if="item.tit6">時間 : {{ $t(`${item.tit6}`) }}</div>
              <div class="flex-tit2" v-if="item.partner" @click="showpartnerChinese(index)">
                {{ $t(`夥伴`) }}: <span style="text-decoration: underline; cursor: pointer">{{ item.partner }} </span>
              </div>
              <div class="flex-tit3" v-if="item.tit3" v-html="$t(`${item.tit3}`)"></div>
              <div class="flex-btn1" v-if="item.btn1">{{ item.btn1 }}</div>
              <div class="flex-btn2" v-if="item.btn2" @click="showtit5Chinese(index)">{{ item.btn2 }}</div>

              <el-button class="confirmMsg-btn1 btn-replay-1 btn-repl-cn-1" v-if="index == 1 && coachId" @click="handleClick('https://youtu.be/sI5hs5YQxQs', item.id, item.tit1, item.tit4, item.tit6)"
                >重溫講座內容</el-button
              >
              <el-button
                class="confirmMsg-btn11 btn-download-1 btn-download-cn-1"
                v-if="index == 1"
                onclick="location.href='https://drive.google.com/file/d/10IFVbU8Q4AAJs6NinENoW_qP7UlFBTq5/view?usp=sharing';"
                >下載講義</el-button
              >
              <el-button class="confirmMsg-btn21 btn-replay-2 btn-repl-cn-2" v-if="index == 3 && coachId" @click="handleClick('https://youtu.be/06Ck7mE7acI', item.id, item.tit1, item.tit4, item.tit6)"
                >重溫講座內容</el-button
              >
              <el-button
                class="confirmMsg-btn2 btn-download-2 btn-download-cn-2"
                v-if="index == 3"
                onclick="location.href='https://drive.google.com/file/d/17ZcQD67otGpa-OBqQb4HHKSc1k3vfxZ5/view?usp=sharing';"
                >下載講義</el-button
              >
              <el-button class="confirmMsg-btn21 btn-replay-3 btn-repl-cn-3" v-if="index == 4 && coachId" @click="handleClick('https://youtu.be/S-vHxUwc0c8', item.id, item.tit1, item.tit4, item.tit6)"
                >重溫講座內容</el-button
              >
              <el-button
                class="confirmMsg-btn2 btn-download-3 btn-download-cn-3"
                v-if="index == 4"
                onclick="location.href='https://drive.google.com/file/d/1j_lpIk0OzSGfPphfhjKOc5hHCenXet9b/view?usp=sharing';"
                >下載講義</el-button
              >
              <el-button
                class="confirmMsg-btn2 btn-download-3 btn-download-cn-4"
                v-if="index == 4"
                onclick="location.href='https://drive.google.com/file/d/1t8e1eVTzlW602Cx-VBrpCr7qB9Z9NNUD/view?usp=sharing';"
                >下載講義</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog">
      <el-dialog v-if="$i18n.locale == 'en'" :visible.sync="NotLoginNotification" style="white-space: normal">
        <div class="dialog-info">
          <p class="dialog-description">Please log in or create an account. Thank you!</p>
        </div>
      </el-dialog>

      <el-dialog v-if="$i18n.locale == 'cn'" :visible.sync="NotLoginNotification" style="white-space: normal">
        <div class="dialog-info">
          <p class="dialog-description">請登錄或創建一個賬戶。謝謝你！</p>
        </div>
      </el-dialog>

      <el-dialog v-if="$i18n.locale == 'en'" :visible.sync="sharingdialogConfirmMessage" style="white-space: normal">
        <div class="dialog-info">
          <p class="dialog-description">Applied. Please check email.</p>
        </div>
      </el-dialog>

      <!-- <el-dialog v-if="$i18n.locale == 'en'" :visible.sync="errorApplication" style="white-space: normal">
        <div class="dialog-info">
          <p class="dialog-description">You have watched </p>
        </div>
      </el-dialog> -->

      <el-dialog v-if="$i18n.locale == 'cn'" :visible.sync="sharingdialogConfirmMessage" style="white-space: normal">
        <div class="dialog-info">
          <p class="dialog-description">已成功報名. 請檢查你的郵箱</p>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="sharingdialog">
        <div class="dialog-info">
          <div
            class="head-portrait"
            :style="{
              backgroundImage: `url(${this.sharing.speaker})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
            }"
          ></div>
          <div class="dialog-info2">
            <p class="dialog-speakerName">{{ sharing.speakerName }}</p>
            <p class="dialog-title" v-html="$t(`${sharing.tit2}`)"></p>
            <p class="dialog-description" v-html="$t(`${sharing.tit5}`)"></p>
          </div>
        </div>
      </el-dialog>
      <el-dialog v-if="$i18n.locale == 'en'" :title="$t(`Partner`)" :visible.sync="sharingdialogpartner" style="white-space: normal">
        <div class="dialog-info">
          <img style="max-width: 250px; width: -webkit-fill-available" :src="sharing.partner_logo" alt="" />
        </div>
      </el-dialog>

      <el-dialog v-if="$i18n.locale == 'cn'" :title="$t(`夥伴`)" :visible.sync="sharingdialogpartner">
        <div class="dialog-info">
          <img style="max-width: 250px; width: -webkit-fill-available" :src="sharing.partner_logo" alt="" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getUserCoachProfile } from '@/api/coachProfile'
import { postEventData, applySharingEvent } from '@/api/sharing'
//import axios from 'axios'
export default {
  name: 'Sharing',
  data() {
    return {
      lang: '',
      user: '',
      swiperOption: {
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      visible: true,
      swiperImg: [
        {
          img: require('../assets/img/0-Banner/DSC00250.jpg'),
        },
      ],
      shargingList: [
        //     FORMAT
        // {
        //     tit1:'Online Sharing Event Title',
        //     tit2:'Speaker Name',
        //     tit3:'Event Description',
        //     tit4:'Date',
        //     tit5:'Speaker bio <br><br> ',
        //     tit6:'Time',
        //     btn1:this.$t("Sharing.btn1"),
        //     btn2:this.$t("Sharing.btn2"),
        //     speaker: require('../assets/images/speaker photo'),
        //     partner: 'Partner name',
        //     partner_logo: require('../assets/images/AASFP_logo.jpg'),
        //     banner:`background:url(${require('../assets/img/1-Box-Images/images_seminar.png')})  no-repeat 100%/100%`
        // }
        {
          id: '7ef6e12e-e964-486a-9573-676aef3dfbdb',
          tit1: 'Understanding Sports Massage',
          tit2: 'Senior Education Officer (Professional Development and Publications), Sports Therapist and Asian Academy for Sports and Fitness Professionals (AASFP), Lecturer',
          tit3: "Stiffness and pain on the next day after training is because of lacking proper relieving methods. Understanding sports massage can help improve students' sports performance, reduce sports fatigue, and prevent sports injuries.",
          tit4: '20 July, 2022',
          tit5: '',
          tit6: '7:00-7:30pm',
          speakerName: 'Cassidy Ho',
          speaker: require('../assets/images/Speaker5.webp'),
          partner: 'AASFP',
          partner_logo: require('../assets/images/AASFP_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner1-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '1ef233a7-91c8-4384-8354-b5ee3e6c2327',
          tit1: 'Common Orthopaedics Injury on Children and Youths',
          tit2: 'Specialist Orthopaedic Surgeon',
          tit4: '20 July, 2022',
          tit6: '7:30-8:30pm',
          tit3: 'Sports have a lot of advantages; however, it will be vulnerable to injuries if we have little accurate knowledge of exercises and limited awareness of safety measures. Different types of sports cause specific risks and injuries to different regions of the body, especially on the skeleton of a growing child. To understand the specific injuries that have different effects in the growing child, coaches could avoid and minimize the potential risk of sports injuries.',
          tit5: 'Fellow of the Hong Kong College of Orthopaedic Surgeons, Fellow of the Hong Kong Academy of Medicine (Orthopaedic Surgery), Fellow of the Royal College of Surgeons of Edinburgh (Orthopaedic Surgery), Fellow of the Royal College of Surgeons of England. <br><br> Dr Keith Wan graduated from the Faculty of Medicine of the Chinese University of Hong Kong and is currently the Associate Consultant of the Department of Orthopaedics and Traumatology in Kwong Wah Hospital, as well as the honorary Clinical Assistant Professor of the Department of Orthopaedics and Traumatology of the Chinese University of Hong Kong.  Dr Wan specializes in the management of sports injuries, adult joint reconstructions and trauma surgeries.  Dr Wan also serves in the Public Information Committee of the Hong Kong College of Orthopaedic Surgeons, the Doctors Commission of the Hong Kong Association of Sports Medicine and Sports Science, and the Medical Committee of the Hong Kong Football Association. <br><br> Dr Wan is a sports lover. He plays football, basketball, rugby, lawn bowl and participates in dragon boat racing. He is also a proud supporter of the Hong Kong Football Team!',
          speakerName: 'Dr Keith Wan',
          partner: 'HKASMSS',
          partner_logo: require('../assets/images/HKASMSS_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/Speaker2.jpeg'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner2-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: 'ad0a9171-dff7-4bb6-b2bf-a9f3d029e944',
          tit1: 'Applications and Guidelines of using Kinesiology Taping',
          tit2: 'Asian Academy for Sports and Fitness Professionals (AASFP) <br>Associate Program, Director and Registered Physiotherapist',
          tit3: 'Kinesiology Taping is a popular taping method in the sports industry in recent years, which is used to prevent sports injuries and improve human mechanics. Simply mastering the skills and applying the principles of sports tapes could be useful to prevent injuries, protect our body and improve performance in sports training.',
          tit4: '27 July, 2022',
          tit5: '',
          tit6: '7:00-7:30pm',
          speaker: require('../assets/images/Speaker6.webp'),
          speakerName: 'Indy Ho',
          partner: 'AASFP',
          partner_logo: require('../assets/images/AASFP_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner3-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '7528a877-8298-479c-8f1d-24e8a6cd5b7c',
          tit1: 'Eat Like an Athlete',
          tit2: 'Consultant Dietitian,See Way Consultancy',
          tit4: '27 July, 2022',
          tit6: '7:30-8:30pm',
          tit3: 'Children and youths need to pay special attention to their diet. They need adequate energy and nutrient requirements for their daily activities and growth. Together with appropriate sports training and nutrition planning, students could maintain a high-standard physical fitness level and healthy bones, to improve their sports skills and strive for better results.',
          tit5: 'Sylvia obtained her Master of Nutrition and Dietetics qualification from the University Sydney, Australia in 2000. She has been practicing in Hong Kong for 20 years specializing mainly on areas of diabetes, cardiac rehabilitation, weight management and other obesity related conditions and also eating disorders. <br><br>Currently, Sylvia is the Chairperson of the Professional Council of the Hong Kong Academy of Accredited Dietitians (HKAAD). She was the Chairperson of the Hong Kong Dietitians Association from 2007 to 2019 and the External Affair Officers from 2019 to 2021, actively promoting Hong Kong’s dietitian profession. She is also the country representative of Hong Kong of the International Confederation of Dietetics (ICD), and the council member of the Hong Kong Association of Sports Medicine and Sport Science (HKASMSS). <br><br> She often provides nutrition seminars to local and international conferences, corporate companies, school and academic institutions. She is also a reputable spokesperson for the dietetic profession providing accurate and up-to-date nutrition education and information to the general public through newspapers, television and radio programs. Besides, she published more than 10 nutrition cookbooks including topics in sports nutrition, diabetes, heart diseases, weight management, nutrition support for cancer patients, vegetarianism, infant and child nutrition and more.',
          speakerName: 'Ms Sylvia Lam',
          partner: 'HKASMSS',
          partner_logo: require('../assets/images/HKASMSS_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/Speaker4.jpg'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner4-6-22-22.jpeg")  no-repeat 100%/100%`,
          past: true,
        },
        {
          id: 'e8be343e-9c80-4789-953f-ffae8a22b98e',
          tit1: 'Sports Training and Design for Students with Special Educational Needs (SEN)',
          tit2: 'Assistant Professor, Department of Rehabilitation Sciences of The Hong Kong Polytechnic University',
          tit4: '24 August, 2022',
          tit6: '7:00-7:45pm',
          tit3: 'Sports coaches can improve different aspects of children and youth with special educational needs through appropriate and specific sports training and lesson plan design.',
          tit5: 'Dr. Clare Yu is currently an Assistant Professor of the Department of Rehabilitation Sciences at the Hong Kong Polytechnic University and serve as the Hon. Treasurer of the Hong Kong Association of Sports Medicine and Sports Science. She contributed to collecting the first set of waist circumference norms, the six-minute walk test data, and the norm of cardiopulmonary fitness (Peak VO2) for Hong Kong Chinese children. Her research focuses on pediatric exercise science, childhood obesity, cardiopulmonary assessment in children, and exercise intervention program for children with autism spectrum disorder. She is a Certified Strength and Conditioning Specialist® (CSCS®) accredited by the National Strength and Conditioning Association (NSCA).',
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/clare.png'),
          speakerName: 'Dr. Clare Yu Chung-wah',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/Banner 5-2022-07-28.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '6a703d44-164d-43ab-af01-97248fc60c54',
          tit1: 'Effective Communication Skills with Students with Special Educational Needs (SEN)',
          tit2: 'Registered Social Worker and Registered Expressive Arts Therapist',
          tit4: '24 August, 2022',
          tit6: '7:45-8:30pm',
          tit3: 'Various studies point out that sports can improve physical fitness and concentration for students with special educational needs (SEN). Appropriate training methods and communications could enable them to engage in sports. Understanding the characteristics and communications skills with SEN students are important for coaches.',
          tit5: 'Wai-yu Leung (Paisley), registered social worker and registered expressive arts therapist. Paisley has abundant experience in offering case and group services to students with special educational needs, emotional disturbance and family issues etc. In the past ten years, she has launched a number of SEN community projects to support the students and their families, and promote social inclusiveness in the long run. She worked as a project manager of Jockey Club Arts Make SENse programme for the past three years, in which she was in collaboration with artists to develop an art intervention to students with SEN.',
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/paisley.jpg'),
          speakerName: 'Paisley Leung',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/Banner 6-2022-07-28.jpg")  no-repeat 100%/100%`,
        },
      ],
      shargingListChinese: [
        //     FORMAT
        // {
        //     tit1:'Online Sharing Event Title',
        //     tit2:'Speaker Name',
        //     tit3:'Event Description',
        //     tit4:'Date',
        //     tit5:'Speaker bio <br><br> ',
        //     tit6:'Time',
        //     btn1:this.$t("Sharing.btn1"),
        //     btn2:this.$t("Sharing.btn2"),
        //     speaker: require('../assets/images/speaker photo'),
        //     partner: 'Partner name',
        //     partner_logo: require('../assets/images/AASFP_logo.jpg'),
        //     banner:`background:url(${require('../assets/img/1-Box-Images/images_seminar.png')})  no-repeat 100%/100%`
        // }
        {
          id: '7ef6e12e-e964-486a-9573-676aef3dfbdb',
          tit1: '按摩恢復及運動鏈矯正技巧',
          tit2: '高級教育主任, 運動治療師及亞洲運動及體適能專業學院(AASFP)講師',
          tit3: '運動隔天總是感到僵硬、疼痛是因為缺少適合的緩解方法。學習正確的運動按摩方法有助提升增加學生運動表現、減輕運動疲勞及預防運動傷害。',
          tit4: '2022年7月20日',
          tit5: '',
          tit6: '7:00-7:30pm',
          speaker: require('../assets/images/Speaker5.webp'),
          speakerName: '何展宏',
          partner: 'AASFP',
          partner_logo: require('../assets/images/AASFP_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner1-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '1ef233a7-91c8-4384-8354-b5ee3e6c2327',
          tit1: '兒童及青少年有關運動的常見骨科問題',
          tit2: '骨科專科醫生、香港骨科醫學院院士、香港醫學專科學院院士（骨科）、英國愛丁堡皇家外科學院骨科院士、英國皇家外科學院院士',
          tit4: '2022年7月20日',
          tit6: '7:30-8:30pm',
          tit3: '運動有很多好處，但若果缺乏運動的正確知識、忽視安全措施，便很容易受傷。骨科問題是青少年在成長時期最常面對的傷患，了解運動對於成長中的兒童的骨骼的影響，從而減低學員運動受傷的風險。',
          tit5: '尹希文醫生畢業於香港中文大學醫學院，現為廣華醫院骨科副顧問醫生，香港中文大學矯形外科及創傷學系榮譽臨床助理教授。主要負責運動醫學、成人關節置換，及骨折創傷等臨床服務及研究。尹醫生同時擔任香港骨科醫學院公共資訊委員會委員，香港運動醫學及科學學會醫生委員會委員，香港足球總會醫藥委員會委員。 <br><br> 尹醫生亦是運動愛好者，參與運動包括足球、籃球、欖球、草地滾球及龍舟，亦是香港足球隊的忠實支持者！',
          partner: 'HKASMSS',
          partner_logo: require('../assets/images/HKASMSS_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/Speaker2.jpeg'),
          speakerName: '尹希文醫生',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner2-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: 'ad0a9171-dff7-4bb6-b2bf-a9f3d029e944',
          tit1: '實戰篇：運動貼布的應用',
          tit2: '亞洲運動及體適能專業學院(AASFP)課程副總監及註冊物理治療師',
          tit3: '運動貼布是近年在體育界很流行的一種貼紮法，用於預防運動創傷、改善人體力學、促進創傷康復等各方面。簡單掌握運動貼布的使用技巧和應用原則，在運動訓練中亦可以來防治傷患、保護身體並促進表現。',
          tit4: '2022年7月27日',
          tit5: '',
          tit6: '7:00-7:30pm',
          speaker: require('../assets/images/Speaker6.webp'),
          speakerName: '何文傑',
          partner: 'AASFP',
          partner_logo: require('../assets/images/AASFP_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner3-6-22-22.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '7528a877-8298-479c-8f1d-24e8a6cd5b7c',
          tit1: '運動員飲食法',
          tit2: '顧問營養師',
          tit3: '青少年運動員需特別注意飲食。他們在營養方面需要有足夠的能量與營養素來滿足活動的需要和促進成長。配合運動訓練和飲食營養，青少年可以建立良好體能和健康骨骼，提升技術並爭取更好成績。',
          tit4: '2022年7月27日',
          tit5: '2000 年畢業於澳洲悉尼大學營養治療碩士課程，現為香港私人執業的高級註冊營養師，有接近二十年為有體重問題，心臟病、糖尿病患者、飲食失調及有相關疾病人士提供營養飲食評估及個人飲食治療的經驗。她現任香港認可營養師學院專業委員會主席，在 2007-2019 年其間曾擔任香港營養師協會會長，2019至2021年擔任協會對外事務主任，全力推動香港註冊營養師專業服務。現為國際營養學聯合會（ICD）香港的地區代表及香港運動醫學與運動科學學會理事會成員。 <br><br> 林小姐曾為各大機構包括政府機構、非牟利機構、銀行、商業機構、製藥公司、電信公司和專上學院及大學等作營養學的講者。她更常為各大報章、電視、電台和網上解答及推廣營養資訊。她曾編著十本以上營養書藉，最新著作有「運動 x 營養 - 講事又講非」、「金牌營養師抗膽固醇營養師私房菜」和「金牌營養師糖尿病營養師甜美食 譜」。其他包括「癌症患者飲食手記」、「營養師瘦身私房菜」及「營養師素食私房菜」「幸福媽咪懷 孕食譜」等等。',
          tit6: '7:30-8:30pm',
          partner: 'HKASMSS',
          partner_logo: require('../assets/images/HKASMSS_logo.jpg'),
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/Speaker4.jpg'),
          speakerName: '林思為',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/coachBanner4-6-22-22.jpeg")  no-repeat 100%/100%`,
        },
        {
          id: 'e8be343e-9c80-4789-953f-ffae8a22b98e',
          tit1: '運動訓練與設計：有特殊學習需要學童篇',
          tit2: '香港理工大學康復科學系 助理教授',
          tit3: '運動教練可以透過合適和針對性的運動訓練、課程設計和制定方案，改善有特殊學習需要的兒童及青年不同方面的能力。',
          tit4: '2022年8月24日',
          tit5: '余頌華博士現為香港理工大學康復科學系助理教授及香港運動醫學及運動科學學會司庫。 她的主要研究領域包括兒科運動科學、兒童及青少年心肺評估、兒童肥胖症以及推廣身體活動與健康。她曾參對本港兒童腰圍數據和心血管關係的分析研究、 6分鐘步行測試和心肺功能測試等研究項目。目前，余博士的研究重點是兒科運動科學、兒童肥胖症、兒童心肺評估以及自閉症譜系障礙兒童的運動干預計劃。 她是美國體能協會 (NSCA) 認可的認證體能專家® (CSCS®)。',
          tit6: '7:00-7:45pm',
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/clare.png'),
          speakerName: '余頌華博士',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/Banner 5-2022-07-28.jpg")  no-repeat 100%/100%`,
        },
        {
          id: '6a703d44-164d-43ab-af01-97248fc60c54',
          tit1: '當教練遇上SEN學生：行為探討及掌握溝通技巧',
          tit2: '註冊社工及註冊表達藝術治療師',
          tit3: '不同研究指出運動能改善有特殊學習需要 (SEN) 的學生的體格及專注力。透過合適的訓練方法與溝通，能夠提升他們對運動的興趣。掌握SEN兒童及青少年的特質及溝通技巧是教練不可或缺的一環。',
          tit4: '2022年8月24日',
          tit5: 'Paisley富有豐富經驗為有特殊教育需要(SEN)、情緒困擾、家庭困難等的兒童及青少年提供個案輔導及小組服務。過往積極推動 SEN 服務，開展多個支援SEN學童和其家庭的計劃，長遠推動社會共融。過往三年擔任賽馬會「藝術有 SENse」計劃的計劃經理，與本地藝術家建構藝術介入手法支援SEN學童。',
          tit6: '7:45-8:30pm',
          btn2: this.$t('Sharing.btn2'),
          speaker: require('../assets/images/paisley.jpg'),
          speakerName: '梁惠茹姑娘',
          banner: `background:url("https://df7ocie544p6c.cloudfront.net/images/Banner 6-2022-07-28.jpg")  no-repeat 100%/100%`,
        },
      ],
      sharingdialog: false,
      sharingdialogpartner: false,
      sharingdialogConfirmMessage: false,
      sharingdialogConfirmMessage1: false,
      errorApplication: false,
      NotLoginNotification: false,
      sharing: [],
      eventData: [
        {
          title_en: 'Sports Training and Design for Students with Special Educational Needs (SEN)',
          title_zh_tw: '運動訓練與設計：有特殊學習需要學童篇',
          date_en: '24 August, 2022 (Wed)',
          date_zh_tw: '2022年8月24日 (三)',
          time_en: '7:00-7:45pm',
          time_zh_tw: '7:00-7:45pm',
        },
        {
          title_en: 'Effective Communication Skills with Students with Special Educational Needs (SEN)',
          title_zh_tw: '掌握與有特殊學習需要兒童及青少年的溝通技巧',
          date_en: '24 August, 2022',
          date_zh_tw: '2022年8月24日 (三)',
          time_en: '7:30-8:30pm',
          time_zh_tw: '7:30-8:30pm',
        },
      ],
      // coach data
      coachId: '',
      coachData: {},
      learn_record: null,
      inspire_learn_record: null,
      safeguarding_learn_record: null,
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.lang = window.localStorage.getItem('locale')
  },
  created() {
    this.getProfiles()
  },
  methods: {
    getProfiles() {
      getUserCoachProfile().then((res) => {
        this.isSaved = res?.isSaved
        this.coachData = res.data
        this.coachId = res.data.user_id
        this.initData(res)
      })
    },
    initData() {
      this.learn_record = this.coachData.learn_record
      this.safeguarding_learn_record = this.coachData.safeguarding_learn_record
      this.inspire_learn_record = this.coachData.inspire_learn_record
    },
    handleClick(link, id, title, date, time, click) {
      const data = {
        event_id: id,
        event_name: title,
        event_date: date,
        event_time: time,
      }
      if (this.coachData.event_record <= 3) {
        applySharingEvent(data)
          .then((res) => {})
          .catch((err) => console.error(err))
        location.href = link
      } else {
        location.href = link
      }
    },
    showtit5(index) {
      this.sharingdialog = true
      this.sharing = this.shargingList[index]
    },
    showpartner(index) {
      this.sharingdialogpartner = true
      this.sharing = this.shargingList[index]
    },
    showtit5Chinese(index) {
      this.sharingdialog = true
      this.sharing = this.shargingListChinese[index]
    },
    showpartnerChinese(index) {
      this.sharingdialogpartner = true
      this.sharing = this.shargingListChinese[index]
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return (this.loggedIn = token == null ? false : true)
    },
    submit() {
      if (this.checkLogin() == true) {
        this.sharingdialogConfirmMessage = true
        console.warn('function called', this.eventData)
        postEventData({ events: this.eventData }).then((res) => {})
      } else {
        this.$alert(this.$t('Tools.warningtext'), this.$t('Tools.warningtitle'), {
          confirmButtonText: 'OK',
          type: 'warning',
          callback: (action) => {
            this.$router.push('/')
          },
        })
      }
    },
    /*submit1() {
      if (this.checkLogin() == true) {
        this.sharingdialogConfirmMessage = true
        console.warn('function called', this.eventData1)
        postEventData({ events: this.eventData1 }).then((res) => {})
      } else {
        this.NotLoginNotification = true
      }
    },*/
  },
}
</script>
<style lang="scss" scoped>
@import '../style/mixin.scss';

// variables
$color-palette-1: #28327b;
$color-palette-2: #f15922;
$color-palette-3: #666666;
$color-palette-4: #d1d4eb;

.line {
  background-color: #d2d2d2;
  height: 1px;
  margin: 60px 10%;
}
.center-background {
  background: url('../assets/img/bg-blue.jpg');
  padding-bottom: 30px;
}
.center-container {
  max-width: 1200px;
  margin: 0 auto;
}
.saved {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.coachAction {
  .banner-con {
    position: relative;
    .top-left-con {
      width: 62%;
      height: 500px;
      background: $color-palette-1;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg) translateX(-9%);
      z-index: 2;
      .line-one {
        width: 338px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 24%;
        right: 2%;
      }
      .line-two {
        width: 120px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 42%;
        right: 2%;
      }
      .bg-tit1 {
        margin: 0 auto;
        margin-top: 150px;
        font-size: 75px;
        font-weight: bold;
        line-height: 75px;
        color: #ffffff;
        font-size: 70px !important;
      }
      .bg-tit2 {
        margin: 0 auto;
        // margin-top: 150px;
        font-size: 75px;
        font-weight: bold;
        line-height: 75px;
        color: #ffffff;
        font-size: 70px !important;
        opacity: 1;
      }
      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        transform: skew(15deg);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .swiper {
      height: 620px;
      .swiper-item1 {
        position: relative;
        overflow: hidden;
        .box {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 88%;
          width: 65%;
          overflow: hidden;
          transform: skew(-15deg) translateX(12%);
          .img {
            position: absolute;
            right: -20%;
            bottom: 0;
            height: 100%;
            width: 130%;
            transform: skew(15deg) translateX(-9%);
            object-fit: cover;
          }
        }
        .top-right-con {
          width: 40%;
          height: 9.1%;
          background: $color-palette-2;
          position: absolute;
          top: 3%;
          right: 0;
          transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
          width: 40%;
          height: 15%;
          background: $color-palette-2;
          position: absolute;
          bottom: 5%;
          left: 0;
          transform: skew(-15deg) translateX(-3%);
        }
      }
    }
  }
  .tit1 {
    font-size: 26px;
    margin: 3% auto;
    width: 80%;
    letter-spacing: 2px;
    text-align: center;
  }
  .tit2 {
    width: 80%;
    font-size: 30px;
    color: $color-palette-2;
    margin: 3% auto;
    p {
      text-align: left;
      margin: 20px 0;
    }
    p:nth-child(1) {
      text-align: center;
      font-weight: bold;
    }
  }
  .dialog-info2 {
    flex-direction: column;
    text-align: left;
    margin-left: 30px;
    .dialog-description {
      word-break: normal;
      word-wrap: break-word;
      white-space: pre-wrap;
      color: $color-palette-3;
      margin-top: 25px;
    }
    .dialog-speakerName {
      font-size: 28px;
      font-weight: bold;
      line-height: 46px;
      color: $color-palette-1;
    }
    .dialog-title {
      word-break: break-word;
      color: $color-palette-3;
    }
  }
  .dialog-info {
    display: flex;
    justify-content: center;
    .head-portrait {
      width: 280px;
      height: 280px;
      background: rgba(0, 0, 0, 0);
      border: 3px solid $color-palette-4;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }
}

img.connet {
  width: 50px;
  margin: 0 10px;
}
.info {
  width: 979px;
  height: 54px;
  font-size: 22px;
  font-family: Graphie;
  font-weight: 600;
  line-height: 28px;
  color: $color-palette-1;
  padding-top: 100px;
  margin: 0 auto;
}
.title-text {
  font-size: 30px;
  padding-top: 100px;
}
.text-blue {
  color: $color-palette-1;
  font-weight: bold;
}
img.connet {
  width: 50px;
  margin: 0 10px;
}

// main button style
.flex-btn2 {
  text-align: center;
  margin: 10px auto;
  width: 521px;
  height: 65px;
  border-radius: 4px;
  line-height: 65px;
  background: #fff;
  color: $color-palette-2;
  border: 1px solid $color-palette-2;
  cursor: pointer;
  border-radius: 12px;
}
// confirm button
.confirmMsg-btn3en,
.confirmMsg-btn3 {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.isdisabled {
  opacity: 0.5;
}

.content-flex {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 50px 0;

  .flex-item {
    margin: 40px 28px 250px 28px;
    width: 586px;
    // height: 500px;
    background: #ffffff;
    box-shadow: 0px 0px 20px $color-palette-4;
    border-radius: 12px;
    overflow: hidden;

    .flex-bg {
      width: 100%;
      height: 282px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .soon {
      position: absolute;
      color: white;
      backdrop-filter: blur(5px);
      text-align: center;
      font-size: 50px;
      font-weight: bold;
    }
    .flex-info {
      text-align: left;

      .speaker_name {
        color: red;
        padding: 0 0.44rem;
        font-size: 20px;
      }

      .btn-replay-1,
      .btn-replay-2,
      .btn-replay-3 {
        position: absolute;
      }

      .btn-download-1,
      .btn-download-2,
      .btn-download-3,
      .btn-download-4 {
        position: absolute;
      }

      @include phoneSize(480px) {
        .btn-replay-1,
        .btn-replay-2 {
          transform: translate(0, 7em);
        }
        .btn-replay-3 {
          transform: translate(0, 11em) !important;
        }

        .btn-repl-cn-1,
        .btn-repl-cn-2 {
          transform: translate(0, 5em) !important;
        }

        .btn-repl-cn-3 {
          transform: translate(0, 9em) !important;
        }

        .btn-download-1,
        .btn-download-2 {
          transform: translate(0, 2em);
        }

        .btn-download-4 {
          transform: translate(0em, 6.5em) !important;
        }

        .btn-download-cn-1 {
          transform: translate(0, 2em);
        }

        .btn-download-cn-4 {
          transform: translate(0em, 5.5em) !important;
        }
      }

      @include tabletSize(1155px) {
        .btn-replay-1,
        .btn-replay-2 {
          transform: translate(0, 7em);
        }
        .btn-replay-3 {
          transform: translate(0, 11em) !important;
        }
        .btn-repl-cn-3 {
          transform: translate(0, 9em) !important;
        }
        .btn-download-1,
        .btn-download-2,
        .btn-download-3 {
          transform: translate(0, 2em);
        }

        .btn-download-4 {
          transform: translate(0em, 6.5em) !important;
        }
        .btn-download-cn-4 {
          transform: translate(0em, 5.5em) !important;
        }
      }

      @include desktopSize(1155px) {
        .btn-replay-1 {
          transform: translate(-16em, 8em);
        }

        .btn-repl-cn-2 {
          transform: translate(-16em, 8em) !important;
        }

        .btn-replay-2 {
          transform: translate(-16em, 15em);
        }
        .btn-download-1 {
          transform: translate(-16em, 3em);
        }
        .btn-download-2 {
          transform: translate(-16em, 10em);
        }
        .btn-download-cn-2 {
          transform: translate(-16em, 4em);
        }
        .btn-replay-3 {
          transform: translate(21em, 7em);
        }
        .btn-download-3 {
          transform: translate(21em, 11.5em);
        }
        .btn-download-4 {
          transform: translate(21em, 15.7em);
        }

        .btn-repl-cn-3 {
          transform: translate(19.4em, 5em);
        }
        .btn-download-cn-3 {
          transform: translate(19.4em, 9.5em);
        }
        .btn-download-cn-4 {
          transform: translate(19.4em, 13.6em) !important;
        }
      }

      .confirmMsg-btn1en,
      .confirmMsg-btn1,
      .confirmMsg-btn11 {
        color: $color-palette-2;
        border: 1px solid $color-palette-2;
        text-align: center;
        border-radius: 16px;
        width: 521px;
        height: 65px;
        margin: 10px auto;
      }

      .confirmMsg-btn2,
      .confirmMsg-btn21 {
        color: $color-palette-2;
        border: 1px solid $color-palette-2;
        text-align: center;
        border-radius: 16px;
        width: 521px;
        height: 65px;
        margin: 10px auto;
      }
    }

    .flex-tit1 {
      padding-left: 33px;
      margin-top: 30px;
      font-size: 28px;
      font-weight: bold;
      line-height: 46px;
      color: #28327b;
    }
    .flex-tit2 {
      padding: 0 33px;
      margin: 20px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #8f98dc;
    }
    .flex-tit3 {
      padding: 0 33px;
      margin-bottom: 52px;
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
      color: #1d1d1d;
    }
    .flex-btn1 {
      text-align: center;
      margin: 10px auto;
      margin-top: 40px;
      width: 521px;
      height: 65px;
      border-radius: 4px;
      line-height: 65px;
      background: $color-palette-2;
      color: #fff;
      border: 1px solid #fff;
    }

    .confirmMsg-btn11en {
      color: $color-palette-2;
      border: 1px solid $color-palette-2;
      text-align: center;
      border-radius: 16px;
      position: absolute;
      width: 521px;
      height: 65px;
      transform: translate(-55%, 215%);
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    .confirmMsg-btn2en {
      color: $color-palette-2;
      border: 1px solid $color-palette-2;
      text-align: center;
      border-radius: 16px;
      position: absolute;
      transform: translate(-55%, 340%);
      width: 521px;
      height: 65px;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
    .confirmMsg-btn21en {
      color: #f15922;
      border: 1px solid #f15922;
      text-align: center;
      border-radius: 16px;
      position: absolute;
      width: 521px;
      height: 65px;
      transform: translate(-55%, 215%);
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
}
</style>

<style lang="scss">
#Sharing {
  .swiper-pagination {
    text-align: right !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 15px;
    right: 45px;
    left: unset !important;
    width: 30%;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
      display: inline-block;
      background: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #f15922;
      opacity: 1;
    }
  }
}
@media screen and (max-width: 480px) {
  #Sharing {
    .swiper-container-horizontal > .swiper-pagination-bullets {
      top: 15px;
      right: 4%;
      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50% !important;
      }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 40.5%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 86%;
          width: 54.5%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 54%;
      height: 368px;
      top: 0;
      left: -16%;
      .bg-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .bg-tit2 {
        margin-top: 2% !important;
        padding-left: 30% !important;
        font-size: 35px !important;
        padding-left: 0;
      }
      .more {
        font-size: 20px;
        bottom: 12px;
        right: 6px;
      }
      .line-two {
        width: 14px;
        top: 33%;
      }
      .line-one {
        width: 60px;
        top: 20%;
      }
    }
    .bottom-left-con {
      width: 48%;
      height: 30%;
      left: -2%;
    }
    .tit1 {
      width: 90%;
      margin: 50px auto;
      font-size: 32px;
      text-align: left;
      letter-spacing: 1px;
    }
    .tit2 {
      width: 90%;
    }
    .list-scroll {
      width: 100%;
      margin: 0% auto;
      overflow: hidden;
      overflow-x: auto;
      .list-bg {
        width: 1100px;
        padding: 0 6%;
      }
    }
  }
}
</style>

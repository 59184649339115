import request from '@/utils/request'

export function postEventData(data) {
  return request({
    method: 'POST',
    url: '/apply-events',
    data,
  })
}
export function applySharingEvent(data) {
  return request({
    method: 'POST',
    url: '/apply-sharing-event',
    data,
  })
}
